
import { AppBar, Card, CardContent, Chip, Dialog, Grid, IconButton, Menu, Toolbar, Typography } from "@material-ui/core";
import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from "react";
import GuardComment from "../guard-comment/GuardComment";
import useRequest from "hooks/useRequest";
import Loader from "components/MainApp/atoms/Loader";
import { GuardStatusService } from "services/GuardStatusService";
import { EventRepository } from "helpers/EventRepository";
import GuardItem from "../guard-item/GuardItem";
import "./styles.scss";


const GuardHistory = ({ devices, open, handleCloseDialog }) => {

    const [alerts, setAlerts] = useState([]);

    React.useEffect(() => {
        setAlerts([]);
        reloadAlerts();
    }, [open])

    const {
        loading,
        beforeSubmit,
        afterSubmit,
        errors,
        dealWithError,
    } = useRequest();

    const reloadAlerts = () => {
        beforeSubmit()
        GuardStatusService.getAlarmsGuardStatus({
            device_id: devices
        }).then(history => {
            afterSubmit();
            setAlerts(history);
        });
    }

    const analizeErrors = (message, errors = {}) => {
        if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
                label: message,
                type: "error",
            });
        }
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleCloseDialog}
            className="dialog"
            PaperProps={{
                style: {
                    backgroundColor: '#fafafc',
                },
            }}
        >
            <AppBar position="static" className="appBarMui">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialog}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <div className="header-tittle">
                        Historico
                    </div>
                </Toolbar>

            </AppBar>

            <div className="alerts-main">
                {alerts.length > 0 &&
                    alerts.map((alert) => {
                        return (<GuardItem key={alert.id} reload={reloadAlerts} alert={alert} showDetailAction={false} ></GuardItem>);
                    })}
            </div>

            {loading && <Loader />}
        </Dialog>
    );
}

export default GuardHistory;