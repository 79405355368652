import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { reducer, reducerPersist } from "./Reducer";

//
// NO PERSISTION
//
const initialState = {
  productFilters: {},
  orderFilters: {},
  prepOrderFilters: {},
  recOrderFilters: {},
  stockFilters: {},
  companyFilters: {}
};
const StateContext = createContext(initialState);

const noPersist = (reducer) => {
  return (state, action) => {
    const newState = reducer(state, action);
    return newState;
  };
};

const RFIDContext = ({ children }) => {
  return (
    <StateContext.Provider value={useReducer(noPersist(reducer), initialState)}>
      {children}
    </StateContext.Provider>
  );
};
RFIDContext.defaultProps = {
  children: null,
};
RFIDContext.propTypes = {
  children: PropTypes.element,
};

const useStore = () => useContext(StateContext);

//
// PERSISTION
//
const initialStatePersist = {};
const StateContextPersist = createContext(initialStatePersist);

const persist = (reducer) => {
  return (state, action) => {
    const newState = reducer(state, action);
    localStorage.setItem("react-store", JSON.stringify(newState));
    return newState;
  };
};

const RFIDContextPersist = ({ children }) => {
  const cache = localStorage.getItem("react-store");
  const initialState = cache ? JSON.parse(cache) : initialStatePersist;
  return (
    <StateContextPersist.Provider
      value={useReducer(persist(reducerPersist), initialState)}
    >
      {children}
    </StateContextPersist.Provider>
  );
};
RFIDContextPersist.defaultProps = {
  children: null,
};
RFIDContextPersist.propTypes = {
  children: PropTypes.element,
};

const useStorePersist = () => useContext(StateContextPersist);

export { RFIDContext, useStore, RFIDContextPersist, useStorePersist, persist };
