
import { Button, Card, CardContent, Chip, Grid, Typography } from "@material-ui/core";
import * as React from 'react';
import "./styles.scss"
import GuardItemDetail from "../guard-item-detail/GuardItemDetail";
import { ProductService } from "services/ProductService";
import { getAlarmType } from "../Parser";
import { ParseDateTimeToday } from "helpers/Parsers";
import Icon from "components/MainApp/atoms/Icon/icon";


const GuardItem = ({ alert, reload, anim = false, showDetailAction = true, deviceHandheldIdentifier = 1 }) => {

    const [openDetail, setOpenDetail] = React.useState(false);
    const [product, setProduct] = React.useState(null);
    const [focus, setFocus] = React.useState("");
    const date = ParseDateTimeToday(alert.created_at);
    const device = alert.device;
    const tag = alert.tag;

    const btnEvaluateModalDetailClick = () => {
        if (openDetail) {
            handleCloseDialog();
        }
    }

    React.useEffect(() => {
        if (tag != null) {
            ProductService.productBySKU(tag.sku).then((prod) => {
                setProduct(prod.results.results[0]);
            })
            if (anim) {
                addAnimation();
                setTimeout(() => {
                    addAnimation();
                }, 2000);
            }
        }
    }, [])

    const addAnimation = () => {
        setFocus("focus")
        setTimeout(() => {
            setFocus("")
        }, 1000);
    }

    const handleCloseDialog = () => {
        setOpenDetail(false);
        reload();
    }



    return (
        <Card mt={0} ml={0} className={`card-content ${focus}`}>
            <button style={{ display: "none" }} id={`buttonEvaluateModalDetail-${alert.id}`} onClick={btnEvaluateModalDetailClick}>See More</button>
            <CardContent onClick={() => setOpenDetail(true)}>
                <Grid container>
                    <Grid xs={8}>
                        <Typography className="txtdate" component="div" align='left'>
                            {date}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <div className="txt-alarm-type">
                            <Chip label={getAlarmType(alert.status)} className={alert.status} />
                        </div>
                    </Grid>

                    <Grid xs={8} className="device-name">
                        {device?.name}
                    </Grid>

                    <Grid xs={4}>
                        <div className="div-ver-detalles">
                            {alert.tag &&
                                <div>
                                    <Icon name="tags" width={38} height={40} fill={"#3C7AF5"} />
                                </div>
                            }
                            <div className="btnver-detalles">
                                <Button className="btnver-detalles" onClick={() => setOpenDetail(true)}>Ver Detalles</Button>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </CardContent>

            <GuardItemDetail alert={alert} open={openDetail} handleCloseDialog={handleCloseDialog} product={product} showActions={showDetailAction} deviceHandheldIdentifier={deviceHandheldIdentifier}></GuardItemDetail>
        </Card >

    );
}

export default GuardItem;