const ParseDate = (date) => {
  return new Date(date).toLocaleDateString();
};
const ParseDateTime = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString() + " " + newDate.toLocaleTimeString();
};

const ParseDateTimeToday = (date) => {
  var newDate = new Date(date);
  var todaysDate = new Date();
  let dateString = newDate.toLocaleDateString();
  let timeString = newDate.toLocaleTimeString()

  if(newDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
    dateString = "HOY";
  }
  return dateString + " " + timeString + "hs";
};

const formatDateToYyyyMmDd = (date) => {
  let month = "" + (date.getMonth() + 1),
    day = "" + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const formatDateTodmy = (date) => {
  let month = "" + (date.getMonth() + 1),
    day = "" + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};


const parseStringToDate = (stringDate) => {
  let dateArray = stringDate.split("/");
  let day = dateArray[0], month = dateArray[1], year = dateArray[2];

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  let date = new Date();
  

  return new Date(year, month - 1, day); 
};

export { ParseDate, ParseDateTime, formatDateToYyyyMmDd,formatDateTodmy, ParseDateTimeToday, parseStringToDate };
