import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import { getAlarmType } from "../Parser";
import "./styles.scss"
import { useState } from "react";


const GuardComment = ({ newState, handleConfirm, open = false, handleClose }) => {

    const [comment, setCommnet] = useState("")

    return (
        <Dialog open={open} onClose={handleClose} className="main-dialog">
            <DialogTitle className="tittle-comment">{getAlarmType(newState)} </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Si deseas, puedes agregar un comentario.
                </DialogContentText>
                <TextField
                    id="outlined-multiline-static"
                    label="Descripción"
                    multiline
                    minRows={6}
                    className="txt-comment"
                    variant="outlined"
                    onChange={(event) => {
                        setCommnet(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions className="dialog-action">
                <Button className="button-bottom" onClick={() => handleConfirm(comment)}>Confirmar</Button>
                <Button className="btn-cancel" onClick={handleClose}>Cancelar</Button>
            </DialogActions>
        </Dialog>

    )
}

export default GuardComment;