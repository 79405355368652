import Environment from "../environment";
import Api from "./ApiService";

export const GuardStatusService = {

    getAlarmsGuardStatus: (params) => {
        return new Promise((resolve, reject) => {
          return Api.fetch(
            `${Environment.api}api/guard_status/?no_page=1`,
            "GET",
            params
          )
            .then((rta) => {
              resolve(rta);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },

      updateGuardStatus: (id, data) => {
        return new Promise((resolve, reject) => {
          return Api.fetch(
            `${Environment.api}api/guard_status/${id}/`,
            "PATCH", data
          )
            .then((rta) => {
              resolve(rta);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
}