import React from 'react';
import './styles.scss';

export class MainAppGenericModal extends React.Component {
    render() {
        const {
            title,
            open,
            content,
            handleClose
        } = this.props;
        return (
            <div className="main-app-generic-modal-container">
                {open && 
                    <div className="main-app-generic-modal-wrapper">
                        <div className="main-app-generic-modal-overlay" onClick={handleClose} data-automation-id="modal-overlay"></div>
                        <div className="main-app-generic-modal-modal-container">
                            {title && <h1 className="main-app-generic-modal-title"> {title} </h1>}
                            {content}
                        </div>
                    </div>
                }
            </div>
        )
    }

}


MainAppGenericModal.propTypes = {
    
};

export default MainAppGenericModal;